<template>
  <div
    id="webChatConversationFooter"
    class="d-flex justify-content-start align-items-center"
    :class="{
      'pane-closed': !displayChatReplyWithoutRestrictionEnabled && !canReply,
    }"
  >
    <!-- displayChatReplyWithoutRestrictionEnabled: remove code below and class above -->
    <div
      v-if="!displayChatReplyWithoutRestrictionEnabled && !canReply"
      class="d-flex align-items-center justify-content-center w-100 cant-reply"
    >
      <em v-if="conversation" class="h4"
        >You cannot reply to this conversation.</em
      >
    </div>

    <div v-else class="d-flex flex-column w-100">
      <div class="d-flex flex-column reply-pane w-100">
        <form id="chatReplyForm">
          <textarea
            id="replyTextArea"
            class="w-100 reply-textarea"
            v-model="replyText_"
            placeholder="Reply..."
            aria-label="Reply..."
            @keydown.meta.enter="commandReply"
            @keyup.ctrl.enter="ctrlReply"
            @keyup.exact="pulseAgentTyping"
          ></textarea>
          <div class="d-flex flex-row align-items-center reply-toolbar">
            <div class="d-flex flex-row align-items-center mr-auto ml-1 pt-1">
              <WebChatAiButton
                v-if="aiAssistantEnabled"
                :botId="botId"
                :conversationId="conversation.conversationId"
                @use-ai-reply="useAiReply"
              />
              <LegacyTemplatePicker
                class="template-picker mr-2"
                :items="templateList"
                templateType="Chat"
                popoverId="template-picker-chat"
                @item-picked="addTemplate"
              />
              <LegacyEmojiPickerChatMenu @select-emoji="insertEmoji" />
              <div class="translate" v-if="languageTranslationEnabled">
                <MgLink class="mr-3">
                  <a
                    href="#"
                    class="translate-link"
                    :class="{ active: translate }"
                    @click="toggleTranslate()"
                  >
                    <MgIcon
                      name="language"
                      class="mr-1 translate-icon"
                      scale="1.2"
                    ></MgIcon
                  ></a>
                </MgLink>
                <em id="textShortcut" class="text-muted" v-if="translate"
                  >Messages will be translated to Spanish (en-{{
                    languageCode
                  }})</em
                >
              </div>
            </div>

            <em id="textShortcut" v-if="replyText_" class="text-muted">{{
              OSReply
            }}</em>

            <MgLink class="ml-3">
              <button
                @click.prevent="reply()"
                class="btn btn-blue"
                type="button"
                :disabled="
                  !displayChatReplyWithoutRestrictionEnabled &&
                  (!canReply || !replyText_)
                "
              >
                <MgIcon class="mr-1" name="paper-plane" />
                Send
              </button>
            </MgLink>
          </div>
        </form>
      </div>
      <div class="typing">
        {{ typingIndicatorText }}
      </div>
    </div>
  </div>
</template>
<script>
import LegacyEmojiPickerChatMenu from "./LegacyEmojiPickerChatMenu";
import LegacyTemplatePicker from "@/components/LegacyTemplatePicker";
import MgIcon from "@/components/MgIcon";
import MgLink from "@/components/MgLink";
import WebChatAiButton from "./WebChatAiButton";
import analytics from "@/util/analytics";
import api from "@/util/api";
import getProfile from "@/util/profile";
import store from "@/store";
import { GETTERS, MUTATIONS, ACTIONS } from "@/util/constants";
import {
  isClosed,
  messageUserName,
  gravatarUrlWithDefaultImage,
} from "@/util/webchat";

export default {
  name: "WebChatConversationFooter",
  store,
  components: {
    MgIcon,
    MgLink,
    LegacyEmojiPickerChatMenu,
    LegacyTemplatePicker,
    WebChatAiButton,
  },
  props: {
    botId: {
      type: Number,
      required: true,
      default: 0,
    },
    conversation: {
      type: Object,
      required: false,
    },
    templateList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      replyText_: "",
      translate: false,
      agentTyping_: false,
      avatarUrl: "",
      languageCode: "es",
    };
  },
  async created() {
    // check if user has a valid gravatar
    const profile = getProfile();
    /* eslint-disable */
    this.avatarUrl = await gravatarUrlWithDefaultImage(
      profile.emailAddress,
      this.botId
    );
    /* eslint-enable */
  },
  watch: {
    conversation() {
      const profile = getProfile();
      const lastMessage = this.$store.getters[
        GETTERS.GET_WEBCHAT_LAST_MESSAGE_SELECTED_CONVERSATION
      ];

      if (
        (!this.displayChatReplyWithoutRestrictionEnabled &&
        messageUserName(lastMessage) === profile.displayName) || this.displayChatReplyWithoutRestrictionEnabled
      ) {
        this.replyText_ = "";
      }
    },
  },
  computed: {
    languageTranslationEnabled() {
      return this.$store.getters[GETTERS.FEATURE_ENABLED](
        "poc-chat-translation"
      );
    },

    displayChatReplyWithoutRestrictionEnabled() {
      return this.$store.getters[GETTERS.FEATURE_ENABLED](
        "rollout-display-chat-reply-without-restrictions"
      );
    },

    aiAssistantEnabled() {
      return this.$store.getters[GETTERS.FEATURE_ENABLED]("permanent-ai");
    },

    canReply() {
      return !isClosed(this.conversation);
    },

    OSReply() {
      return navigator.platform.indexOf("Mac") === 0 ||
        navigator.platform === "iPhone"
        ? "Cmd + Enter to reply"
        : "Ctrl + Enter to reply";
    },

    typingIndicatorText() {
      return this.$store.state.webChat.typingText;
    },
  },
  methods: {
    async reply() {
      const profile = getProfile();

      if (this.replyText_) {
        // Don't let indicator "spill over" after message is sent
        this.$store.dispatch(ACTIONS.CLEAR_WEBCHAT_OUTGOING_TYPING_TIMER);
        this.agentTyping_ = false;
        const endpoint = `${this.botId}/hitl`;
        let body = {
          conversationId: this.conversation.conversationId,
          message: this.replyText_,
          agentName: profile.displayName,
          avatarUrl: this.avatarUrl,
          translate: this.translate,
          languageCode: this.languageCode,
        };
        const headers = {
          "x-disable-loading": true,
        };
        this.replyText_ = "";
        await api.post(endpoint, body, { headers });

        analytics.track("Sent chat reply", { chatReply: body });
      }
    },

    useAiReply(aiReplyText) {
      this.replyText_ = aiReplyText;
    },

    toggleTranslate() {
      this.translate = !this.translate;
    },

    pulseAgentTyping() {
      if (!this.$store.state.webChat.outgoingTypingTimer) {
        this.sendAgentTypingNotification();
        this.$store.commit(
          MUTATIONS.SET_WEBCHAT_OUTGOING_TYPING_TIMER,
          setInterval(() => {
            if (this.agentTyping_) {
              this.sendAgentTypingNotification();
            } else {
              this.$store.dispatch(ACTIONS.CLEAR_WEBCHAT_OUTGOING_TYPING_TIMER);
            }
            this.agentTyping_ = false;
          }, 3000)
        );
      } else {
        this.agentTyping_ = true;
      }
    },

    sendAgentTypingNotification() {
      const profile = getProfile();

      this.$store.state.webChat.signalRConnection.send(
        "Typing",
        this.conversation.conversationId,
        profile.displayName,
        this.avatarUrl,
        3000
      );
    },

    handleEmojiClick(emoji) {
      this.replyText_ += emoji.unicode;
    },

    insertEmoji(emoji) {
      const replyTextarea = document.getElementById("replyTextArea");
      const caret = replyTextarea.selectionStart;

      if (!this.replyText_) {
        this.replyText_ = emoji.unicode;
      } else {
        var partOne = this.replyText_.substring(0, caret);
        if (partOne.length > 0 && partOne.charAt(partOne.length - 1) !== " ") {
          partOne += " ";
        }
        var partTwo = this.replyText_.substring(caret);
        if (partTwo.length > 0 && partOne.charAt(0) !== " ") {
          partTwo = " " + partTwo;
        }
        this.replyText_ = partOne + emoji.unicode + partTwo;
      }

      analytics.track("Inserted Emoji", {
        Emoji: emoji.unicode,
      });
    },

    commandReply() {
      if (
        navigator.platform.indexOf("Mac") === 0 ||
        navigator.platform === "iPhone"
      ) {
        this.reply();
      }
    },

    ctrlReply() {
      if (
        navigator.platform.indexOf("Mac") !== 0 &&
        navigator.platform !== "iPhone"
      ) {
        this.reply();
      }
    },

    addTemplate(item) {
      this.replyText_ = item.message;
      analytics.track("Inserted Chat Template");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

#webChatConversationFooter {
  position: sticky;
  bottom: 0;
  height: fit-content;
  padding: 2px 1rem 0 1rem;

  &.pane-closed {
    padding: 0;
  }

  .reply-pane {
    border: 1px solid $input-border;
    border-radius: 4px;

    .reply-textarea {
      border: none;
      border-radius: 4px;
      font-size: 16px;
      font-weight: $font-weight-light;
      margin-right: $grid-gutter-width/2;
      outline: none;
      padding: 0.75rem;
      resize: none;
      height: 64px;
    }

    .reply-toolbar {
      background-color: $message-content-bg;
      padding: 0.5rem;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .cant-reply {
    height: 94px;
    background-color: $gray-light-bg;
  }

  .typing {
    font-weight: $font-weight-bold;
    font-size: 11px;
    line-height: 1rem;
    height: 1rem;
    color: $gray-subtitle;
    padding-left: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
  }

  .translate {
    margin-left: 10px;
  }

  a.translate-link.active {
    color: #003a5c;
  }

  .template-picker {
    margin-bottom: 3px;
  }
}
</style>
